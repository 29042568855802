<template>
  <div>
    <div v-if="!pageIndexApiDataIsLoading">
      <company-licence-component/>

      <div class="row">
        <div class="col-md-12 m-b-0">
          <router-link class="btn btn-white m-r-5 text-orange" to="/query-playground"><i class="fa fa-home fa-fw fa-lg text-orange"></i></router-link>
        </div>
      </div>

      <!--<h1 class="page-header">Playground  <small class="lead">.</small></h1>-->
      <hr class="bg-grey-lighter"/>

      <div class="row m-b-5">
        <div class="col-md-12 m-b-0">
        </div>
      </div>

      <div v-if="pageIndexApiData != null">
        <div class="row text-right m-b-5">
          <div class="col-12">
            <a href="#" class="btn btn-dark m-l-5" @click="$bvModal.show('formModal')"><i class="fa fa-filter fa-fw fa-lg"></i></a>
          </div>
        </div>
        <div class="row">
          <div class="col-12" v-if="queryApiData != null">
            <div class="row">
              <div class="col-12" >
                <div class="invoice">
                  <div class="invoice-company">

                    <span class="pull-right hidden-print">
                        <a :href="queryApiData.document_url_pdf" target="_blank" class="btn btn-sm btn-white m-b-10 mr-2" v-if="queryApiData.document_url_pdf != null"><i class="fa fa-file-pdf text-danger fa-fw fa-lg"></i> PDF</a>
                    </span>
                    <h3 class="page-header">{{ queryApiData.warehouse_name }}<br/>
                      <small class="lead">Rapport d'Activités  du {{ queryApiData.date_from }} au {{ queryApiData.date_to }}</small></h3>

                  </div>
                  <div class="invoice-content">
                    <div>
                    </div>
                  </div>
                  <div class="invoice-footer">
                    <p class="text-center m-b-5 f-w-600">
                      MERCI DE VOTRE FIDELITE
                    </p>
                    <p class="text-center">
                      <span class="m-r-10"><i class="fa fa-fw fa-lg fa-globe"></i> {{ queryApiData.company_website }}</span>
                      <span class="m-r-10"><i class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ queryApiData.company_phone }}</span>
                      <span class="m-r-10"><i
                          class="fa fa-fw fa-lg fa-envelope"></i> {{ queryApiData.company_email }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" v-else>
            <div class="panel">
              <div class="panel-body">
                <div class="invoice">
                  <div class="invoice-company">

                    <h3 class="page-header">Suivi des vente &amp; encaissement.<br/>
                    <small class="lead">Dans cette section, vous pouvez suivre les ventes et encaissements d'un
                      emplacement donné sur la période selectionnée.
                    </small></h3>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-12">
          </div>
          <div class="col-12">
          </div>
        </div>
        <!-- Dialogs -->
        <b-modal
            centered
            hide-header
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            id="formModal"
            title="Modal Title"
            size="md"
            @show="()=>{
              this.queryApiDataErrorMessage = null;
            }"
            @hidden="()=>{}">

          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-danger" @click="$bvModal.hide('formModal')"><i
                  class="fa fa-times fa-fw fa-lg"></i></a>
            </p>
          </div>

          <h1 class="page-header">Title <small class="lead"> Subtitle.</small></h1>
          <div class="row" v-if="queryApiDataIsLoading">
            <div class="col-12 m-b-10">
              <small class="lead text-warning"><i class="fas fa-spinner fa-pulse"></i> Chargement en cours...</small>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12" v-if="queryApiDataErrorMessage != null">
              <div class="alert alert-danger">
                {{ queryApiDataErrorMessage }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <form
                  @submit.prevent="queryData">
                <div class="form-group row">
                  <div class="col-12">
                    <label>Choisir un code localité</label>
                    <v-select label="name" :options="pageIndexApiData.location_codes"
                              :reduce="item => item.code"
                              v-model="form.location_code"/>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-12">
                    <label>Choisir un point de vente</label>
                    <v-select label="name" :options="pageIndexApiData.warehouses"
                              :reduce="item => item.id"
                              v-model="form.warehouse_id"/>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-12">
                    <label>Définir la période</label>
                    <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                       opens="right"
                                       :ranges="dateRanges"
                                       :locale-data="{
                                                      format: 'dd-mm-yyyy',
                                                      separator: ' - ',
                                                      applyLabel: 'Appliquer',
                                                      cancelLabel: 'Annuler',
                                                      weekLabel: 'S',
                                                      customRangeLabel: 'Plage Perso.',
                                                      daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                      monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                      firstDay: 1
                                                    }"
                                       :singleDatePicker="false"
                                       :timePicker="false"
                                       :timePicker24Hour="true"
                                       :showWeekNumbers="false"
                                       :showDropdowns="false"
                                       :autoApply="true"
                                       v-model="form.date_range"
                                       @update="()=>{}"
                                       :linkedCalendars="false">
                      <template v-slot:input="picker" class="text-right">
                        {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                        <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                      </template>
                    </date-range-picker>
                  </div>
                </div>
                <!--
                <div class="form-group row">
                  <div class="col-12">
                    <div class="checkbox checkbox-css checkbox-inline">
                      <input type="checkbox" id="pdf" v-model="form.to_pdf"/>
                      <label for="pdf"><i class="fas fa-download text-warning"></i>
                        Télécharger au format PDF</label>
                    </div>
                  </div>
                </div>
                -->
                <div class="form-group row">
                  <div class="col-12 text-right">
                    <button :disabled="queryApiDataIsLoading" type="submit" class="btn btn-dark">
                      <i class="fas fa-spinner fa-spin" v-if="queryApiDataIsLoading"></i>
                      <i class="fas fa-filter" v-else></i> Filtrer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <!--
          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-comment fa-fw fa-lg"></i></a>
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-cogs fa-fw fa-lg"></i></a>
            </p>
          </div>
          -->
        </b-modal>
        <b-modal
            centered
            hide-header
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            id="queryModal"
            title="Modal Title"
            size="xl"
            @show="()=>{}"
            @hidden="()=>{}">

          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-danger" @click="$bvModal.hide('queryModal')"><i
                  class="fa fa-times fa-fw fa-lg"></i></a>
            </p>
          </div>

          <div>

          </div>

          <!--
          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-comment fa-fw fa-lg"></i></a>
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-cogs fa-fw fa-lg"></i></a>
            </p>
          </div>
          -->
        </b-modal>
      </div>
    </div>
    <div class="panel-loader" v-else><span class="spinner-small"></span></div>
  </div>
</template>


<script>
import pageApi from '../../store/api/playground/query-playground-page-api'
import LocalStorage from "../../store/local-storage";
import CompanyLicenceComponent from '../../components/app/base/CompanyLicenceComponent.vue'

export default {
  components: {
    CompanyLicenceComponent,
  },
  props: {},
  watch: {},
  computed: {},
  methods: {
    //Initialization Methods
    loadPageScripts() {
    },

    formatDateInput (value) {
      if (value) {
        return window.moment(String(value)).format(window.appDefaultDisplayDateTimeFormat)
      }
      return null;
    },

    //Other Methods
    async getPageIndexApiData() {
      this.pageIndexApiDataIsLoading = true;
      this.pageIndexApiDataErrorMessage = null;
      try {
        const response = await pageApi.getIndexData();
        //console.log(response);
        this.pageIndexApiData = response.data;
        this.pageIndexApiDataIsLoading = false;
        this.pageIndexApiDataErrorMessage = null;
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
            this.pageIndexApiDataIsLoading = false;
            this.pageIndexApiDataErrorMessage = error.response.data.message;
            LocalStorage.clearSessionData();
            this.$router.push('login');
          } else {
            this.pageIndexApiDataIsLoading = false;
            this.pageIndexApiDataErrorMessage = error.response.data.message;
            //
            this.$swal({
              icon: 'error',//'info','success','warning','error','question',
              toast: true,
              position: 'bottom-end',
              timer: 3000,
              showConfirmButton: false,
              title: 'HTTP ' + error.response.status,
              text: error.response.data.message,
            })
          }
        } else {
          this.pageIndexApiDataIsLoading = false;
          this.pageIndexApiDataErrorMessage = error.message;
          //
          this.$swal({
            icon: 'error',//'info','success','warning','error','question',
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            showConfirmButton: false,
            //title: error.message
            text: error.message,
          })
        }
      }
    },

    async queryData() {
      this.queryApiDataIsLoading = true;
      this.queryApiDataErrorMessage = null;
      this.queryApiDataValidationErrors = [];
      try {
        const response = await pageApi.queryData({
          location_code: this.form.location_code,
          warehouse_id: this.form.warehouse_id,
          date_from: this.formatDateInput(this.form.date_range.startDate),
          date_to: this.formatDateInput(this.form.date_range.endDate),
          to_pdf: this.form.to_pdf
        });
        //console.log(response);
        this.queryApiData = response.data;
        this.queryApiDataIsLoading = false;
        this.queryApiDataErrorMessage = null;
        this.queryApiDataValidationErrors = [];

        //
        this.$bvModal.hide('formModal');
        //this.$bvModal.show('queryModal');

      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.queryApiDataIsLoading = false;
          this.queryApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.queryApiDataValidationErrors = error.response.data.errors;
          } else {
            this.queryApiDataValidationErrors = [];
          }
        } else {
          this.queryApiDataIsLoading = false;
          this.queryApiDataErrorMessage = error.message;
          this.queryApiDataValidationErrors = [];
        }
      }
    },
  },
  data: function () {

    let today = new Date()
    today.setHours(0, 0, 0, 0)
    let todayEnd = new Date()
    todayEnd.setHours(11, 59, 59, 999);
    let yesterdayStart = new Date()
    yesterdayStart.setDate(today.getDate() - 1)
    yesterdayStart.setHours(0, 0, 0, 0);
    let yesterdayEnd = new Date()
    yesterdayEnd.setDate(today.getDate() - 1)
    yesterdayEnd.setHours(11, 59, 59, 999);
    let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);


    return {
      appName: window.appConfig.appName,
      appCurrency: window.appConfig.appCurrency,
      intervalId01: 0,

      form: {
        location_code: null,
        warehouse_id: null,
        date_range: {
          startDate: null,
          endDate: null,
        },
        to_pdf: false,
      },

      dateRanges: {
        "Aujourd'hui": [today, todayEnd],
        "Hier": [yesterdayStart, yesterdayEnd],
        "Mois en cours": [thisMonthStart, thisMonthEnd],
        "Mois dernier": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)
        ],
        "Année en cours": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)
        ],
        "Année dernière": [
          new Date(today.getFullYear() - 1, 0, 1),
          new Date(today.getFullYear()- 1, 11, 31, 11, 59, 59, 999)
        ],
      },

      //API Data
      //
      pageIndexApiData: null,
      pageIndexApiDataIsLoading: false,
      pageIndexApiDataErrorMessage: null,

      queryApiData: null,
      queryApiDataIsLoading: false,
      queryApiDataErrorMessage: null,
      queryApiDataValidationErrors: [],
    };
  },
  created: function () {
    this.getPageIndexApiData();
  },
  mounted: function () {
    this.loadPageScripts();
  },
  beforeDestroy: function () {
  },
}
</script>
